import React from "react"
import Container from "../components/container";
import {graphql} from "gatsby";
import Img from "gatsby-image"
import SEO from "../components/seo"
import {Col, Row, Divider} from "antd";

export default function BlogPost({data}) {
    let post = data.directus.news[0];
    return (
        <Container>
                <SEO title={post.headline}/>

            <div style={{padding: 50}}>
                <h1>{post.headline}</h1>
                <Row>
                    { post.image && <Col xs={24} md={12}>
                        <Img fadeIn fluid={post.image.imageFile.childImageSharp.fluid}/>
                    </Col> }
                    <Col>
                    </Col>
                    <Col xs={24} md={12}>
                        <div style={{padding:20}} dangerouslySetInnerHTML={{__html: post.text}}/>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}


export const query = graphql`  
query($slug: String!){
directus {
      news(filter: {slug: {_eq: $slug}})  {
        headline
        status
        text
        image{
          id
          imageFile{
            childImageSharp{
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
        slug
      }
    }
  }`

